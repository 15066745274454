window.__tnt || (window.__tnt = {});

(function(w, d, t) {

    var settings = {};
    var app = {
    

        _init: function() {
            settings = JSON.parse(d.getElementsByClassName('asset-paging-container')[0].dataset.settings);
            settings.eventScroll = false;
            app.fetchAssets();
        },

        adConflict: function() {
            var anchor = d.querySelector('.tnt-ads-anchor');
            if (anchor !== null) {
                var anchorClose = d.querySelector('.tnt-ads-anchor .close'),
                    paging = d.querySelector('.asset-paging'),
                    height = anchor.clientHeight,
                    anchorHeight = height + 20;

                paging.style.marginBottom = anchorHeight + 'px';
                anchorClose.addEventListener('click', function() {
                    paging.style.marginBottom = '0';
                });
            }
        },

        appendLinks: function(assets) {
            var next, prev;

            // try to match in index
            for (var i=0; i < assets.length; ++i) {
                if (assets[i].id === settings.uuid) {
                    next = assets[i+1];
                    prev = assets[i-1];
                }
            }

            // no index match
            if (!next && !prev) {
                prev = assets[0];
                next = assets[1];
            // end of list, prev no next
            } else if (!next && prev) {
                next = assets[0];
            // start of list, next no prev
            } else if (next && !prev) {
                prev = assets[assets.length - 1];
            }

            // append links
            if (prev) {
                var targetPrev = d.getElementById('paging-prev-' + settings.uuid);
                targetPrev.appendChild(app.populateTemplate(prev, 'prev'));
                targetPrev.style.display = 'block';
                setTimeout(function() {
                    targetPrev.classList.remove('offset');
                }, 200);
            }
            if (next){
                var targetNext = d.getElementById('paging-next-' + settings.uuid),
                    shareContainer = d.querySelector('.share-container.headline-right');
                targetNext.appendChild(app.populateTemplate(next, 'next'));
                targetNext.style.display = 'block';
                if (!settings.hasPageBreak) {
                    setTimeout(function() {
                        targetNext.classList.remove('offset');
                    }, 200);
                }
                // fix conflicting content
                if (shareContainer !== null){
                    if (settings.isFluid) {
                        shareContainer.style.display = 'none';
                    } else {
                        shareContainer.classList.add('hidden-sm');
                    }
                }
            }

            // attach listeners
            app.eventListeners();
        },

        eventListeners: function() {
            w.addEventListener('load', app.showCondensed, false);
            w.addEventListener('resize', app.showCondensed, false);
            if (settings.assetType === 'article' && !settings.hasPageBreak) {
                w.addEventListener('scroll', app.showOnScroll, false);
                w.addEventListener('wheel', app.showOnScroll, false);
            }
            if (w.innerWidth <= 991) {
                window.addEventListener('scroll', app.adConflict, false);
                window.addEventListener('wheel', app.adConflict, false);
            }
        },

        eventTracking: function() {
            var link = d.querySelector('#asset-paging-' + settings.uuid + ' a'),
                action = (link.dataset.direction === 'right') ? 'next' : 'previous';

            link.addEventListener('click', function() {
                t.trackEvent({
                    'category': 'tnt-prev-next',
                    'action': action,
                    'label': settings.assetType,
                    'value': '1'
                });
                return true;
            });
        },

        fetchAssets: function() {
            var request = new XMLHttpRequest();
            request.onreadystatechange = function() {
                if (request.readyState == 4 && request.status == 200) {
                    var result = JSON.parse(request.responseText);
                    if (typeof result.assets === 'object' && result.assets.length > 0) app.appendLinks(result.assets);
                }
            };
            request.open('GET', settings.nextUrl);
            request.send();
        },

        populateTemplate: function(asset, pos) {
            var df = t.template(d.getElementById('asset-paging-template')),
                imgContainer, posDir, posTitle,
                textTrun = 80,
                url = (!settings.blockId) ? asset.url : asset.url + settings.blockId;
            var imgPreview = (asset.preview && asset.preview != 'undefined') ? true : false;

            if (pos === 'next') {
                posDir = 'right';
                posTitle = (settings.isObit) ? 'Next' : 'Next Up';
                url += '#tncms-source=article-nav-next';
            } else {
                posDir = 'left';
                posTitle = 'Previous';
                url += '#tncms-source=article-nav-prev';
            }

            if (settings.isAdminVenue) {
                url = url.replace('https://' + settings.systemDomain, '');
                url = '/' + settings.siteName + '/tncms/admin/action/main/preview/site/-' + url;
            }
            
            // Add URLs to Speculation Rules
            app.prerender(url);

            // Preview Image
            if (imgPreview) {
                var img = d.createElement('img');
                img.setAttribute('alt', asset.title);
                img.setAttribute('data-src', asset.preview);
                img.setAttribute('width', '80');
                img.setAttribute('height', '80');
                img.classList.add('media-object', 'lazyload');
                imgContainer = d.createElement('div');
                imgContainer.classList.add('media-left');
                imgContainer.appendChild(img);
                textTrun = 60;
            }

            // Arrow
            var arrow = df.querySelector('.arrow');
            arrow.setAttribute('href', url);
            arrow.setAttribute('data-direction', posDir);
            arrow.querySelector('span').classList.add('fas', 'tnt-angle-' + posDir);
            arrow.querySelector('.direction').innerHTML = posTitle.substr(0, 4);

            // Overlay
            var overlay = df.querySelector('.overlay');
            overlay.setAttribute('href', url);
            overlay.setAttribute('data-direction', posDir);
            overlay.querySelector('.sr-only').innerHTML = posTitle;
            df.querySelector('.head small').innerHTML = posTitle;
            df.querySelector('.media-heading').innerHTML = t.truncateStr(asset.title.replace(/(<([^>]+)>)/ig, ''), textTrun, '&hellip;');
            df.querySelector('.media-body p').innerHTML = t.truncateStr(asset.summary.replace(/(<([^>]+)>)/ig, ''), textTrun, '&hellip;');

            // Append Preview Image
            if (imgPreview) df.querySelector('.media').prepend(imgContainer);

            return df;
        },
        
        prerender: function(url) {
            var script = document.createElement('script');
            var specRules = {
                "prerender": [{
                    "urls": [url], 
                    "eagerness": "moderate" 
                 }]
            };

            script.setAttribute('type','speculationrules');
            script.innerText = JSON.stringify(specRules);
            
            document.head.appendChild(script);
        },

        showCondensed: function() {
            var sCondTrigger = 'trigger-condensed';
            if (w.innerWidth > 991 && w.innerWidth < 1296) {
                var left = w.scrollLeft,
                    offsetLeft = d.getElementById('main-page-container').offsetLeft,
                    distance = (offsetLeft - left);
                if (distance < 55) {
                    d.body.classList.add(sCondTrigger);
                } else {
                    d.body.classList.remove(sCondTrigger);
                }
            } else {
                d.body.classList.remove(sCondTrigger);
            }
        },

        showOnScroll: function() {
            if (
                !settings.scrollEvent
                && d.querySelector('.asset-body') !== null
                && !d.body.classList.contains('trigger-disabled')
                && !t.elementOnScreen('#site-header')
                && !t.elementOnScreen('#asset-photo-carousel')
                && !t.elementOnScreen('.asset-masthead')
                && !t.elementOnScreen('.asset-showcase-image')
                && (t.regions == undefined || !t.regions.stickyAnchor.hasContent)
            ) {
                settings.scrollEvent = true;
                var nextTrigger = 'trigger-next';
                setTimeout(function() {
                    if (t.elementOnScreen('.asset-body', 250)) {
                        d.body.classList.remove(nextTrigger);
                    } else {
                        d.body.classList.add(nextTrigger);
                    }
                    settings.scrollEvent = false;
                }, 200);
            }
        }
    };

    try {
        if (d.readyState == 'loading') {
            d.addEventListener('DOMContentLoaded', app._init, false);
        } else {
            app._init();
        }
    }
    catch(e){
        if (w.console) {
            w.console.warn(e);
        }
    }
})(window, document, __tnt);